<template>
  <div>
    <b-card
      class="mb-5"
    >
      <div class="vgt-wrap">
        <div class="vgt-inner-wrap">
          <div class="vgt-responsive">
            <table class="vgt-table bordered ">
              <thead>
                <tr>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('partner_name')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('username')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('table_name')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('balance')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('user_ip')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('connected_time')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('last_active_time')" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="user in items"
                >
                  <tr
                    :key="user.id"
                  >
                    <td class="vgt-left-align">
                      {{ user.partner_name }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.username }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.table_name }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.balance }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.user_ip }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.connected_time }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.last_active_time }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import OnlineUser from '@/models/OnlineUser'
import { EventBus } from '../../../eventbus'

export default {
  name: 'OnlineUsers',
  components: {
    BCard,
  },
  data() {
    return {
      items: [],
      loading: true,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await OnlineUser.list()
      this.items = response.data.data
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
  },
}
</script>

<style>

</style>
